
/* Containers */
.container {
  width: 60%;
  margin: 80px auto 80px auto;
  box-shadow: 10px 10px 50px lightgrey;
  padding: 15px 40px;
}
.downloadContainer {
  width: 93%;
  margin: 10px auto;
  padding-bottom: 20px;
}


/* Uploadform */
.uploadForm {
  margin: 40px 30px 0 30px;
}
.formRow {
  border-bottom: 1px solid lightgrey;
  padding: 10px 0;
  /* children */
  display: flex;
  flex-flow: row;
}
.formRow:last-of-type {
  border-bottom: none;
}

/* Style form elements */
.label {
  width: 180px;
  line-height: 30px;
  font-weight: bold;
}
.versionInput {
  width: 140px;
}
.changelogInput {
  width: -webkit-calc(100% - 180px);
  width:    -moz-calc(100% - 180px);
  width:         calc(100% - 180px);
  border: 1px solid lightgrey;
  font-size: 15px;
  border-radius: 5px;
  height: 100px;
  padding: 5px;
}
.releaseDateInput {
  width: -webkit-calc(100% - 180px);
  width:    -moz-calc(100% - 180px);
  width:         calc(100% - 180px);
}
.buildSelect {
  font-size: 17px !important;
  width: 150px;
  margin: auto 0;
}
.fileInput {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.uploadButton {
  color: #0074af;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  border: none;
  margin-left: 0;
  margin-top: 6px;
}
.publishButton {
  background-color: #0074af;
  color: white;
  font-weight: bold;
  font-size: 14.5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  height: 32px;
  width: 120px;
  display: block;
  margin: 60px auto 00px auto;
}
.uploadButton:hover, .publishButton:hover {
  color: darkgray;
}
