
/* Containers */
.container {
  width: 75%;
  margin: 60px auto 80px auto;
  box-shadow: 10px 10px 50px lightgrey;
  padding: 15px 40px;
}
.downloadContainer {
  width: 93%;
  margin: 10px auto;
  padding-bottom: 20px;
}
.downloadSubcontainer {
  width: 90%;
  margin: 0 auto;
  padding-top: 15px;
}


/* Downloads */
.versionRow {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.versionNumber {
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  margin: 0;
}
.releaseDate {
  font-size: 15px;
  line-height: 25px;
  text-align: left;
  width: 160px;
  margin: 0;
}
.downloadLink, .removeLink, .promoteLink {
  line-height: 25px;
  margin: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
  color: #0074af;
  border: none;
  background-color: transparent;
}
.downloadLink:hover, .removeLink:hover {
  color: #bf4b11 !important;
}
.removeLink {
  width: 60px;
  text-align: right;
}
.promoteLink {
  width: 110px;
  text-align: right;
}

/* Labels */
.subtitleRow {
  margin: 40px 0 12px 20px;
  display: flex;
  flex-flow: row;
}
.subtitleRow h2 {
  font-weight: normal;
}
.moreButton {
  color: #0074af;
  background-color: transparent;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border: none;
  margin-left: 20px;
  margin-top: 5px;
}
.moreButton:hover {
  color: darkgray;
}
