
.container {
  height: 75px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  border-bottom: 1px solid #E1DFDF;
  background-color: white;

  padding: 0 20px;
  margin: 0px;
}

/* Logo */

.logoContainer {
  margin-left: 10%;
  float: left;
  height: 75px;
  display: flex;
  align-items: center;
}

.logo {
  margin-left: 20px;
  height: 43px;
}

/* Navigation items */

.itemContainer {
  height: 75px;
  margin-left: auto;
  margin-right: 20%;

  /* Arrange children */
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
