
/* Collapsible styling */
.collapsibleItemOpen, .collapsibleItemClosed {
  border: 0.5px solid lightgray;
  border-bottom: none;
  cursor: pointer;
}
.header {
  padding: 15px;
  line-height: 35px;
  font-size: 15.5px;
}
.firstRow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.lastRow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 0.5px solid lightgray;
}
.arrow {
  margin: 8px 22px 0 0;
  height: 20px;
  float: right;
  /* -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); */
}

.collapsibleItemOpen img {
  animation: rotateOpen 300ms linear forwards;
}
.collapsibleItemClosed img {
  animation: rotateClosed 300ms linear forwards;
}
@keyframes rotateOpen {
  from { transform: rotate(180deg); }
  to { transform: rotate(0deg); }
}
@keyframes rotateClosed {
  from { transform: rotate(0deg); }
  to { transform: rotate(180deg); }
}

/* Content styling */
.collapsibleContent {
  padding: 0px 30px 10px 30px;
  cursor:default;
}

.collapsibleContent li:not(:first-child) {
  margin-top: 7px;
}
