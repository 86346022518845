.Modal {
    position: fixed;
    z-index: 15;
    background-color: white;
    width: 40%;
    left: 30%;
    top: 15%;
    padding: 10px 16px;
    border-radius: 5px;
}

.ultrawide {
  width: 70%;
  left: 15%;
  top: 5%;
}
