
.navItemContainer {
  height: 100%;
  width: 165px;
}


.labelContainer {
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0;
  margin-top: 20px;
}

.label, .orderLabel {
  padding: 2px 0 !important;
  font-size: 17px !important;
  line-height: 35px;
  font-weight: 400;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.label {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}
.label:hover {
  color: #4d4d4d;
}

.selected,
.selected.label:hover {
  font-weight: 600;
}

.selected img {
  display: inline;
}

.arrow {
  display: none;
  width: 12px;
  margin: 0 auto;
}
