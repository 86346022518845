
.tableContainer {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  margin: 30px 15px 30px 15px;
  max-height: 50vh;
  overflow-y: scroll;
}



.closeButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: block;
  height: 30px;
  width: 130px;
  margin: 15px auto 5px auto;
  font-size: 14.5px;
  background-color: #0074af;
}
