
/* Containers */
.container {
  width: 60%;
  margin: 80px auto 80px auto;
  box-shadow: 10px 10px 50px lightgrey;
  padding: 15px 40px;
}
.downloadContainer {
  width: 93%;
  margin: 10px auto;
  padding-bottom: 20px;
}

.downloadContainer h1 {
  margin-bottom: 50px;
}
